<template>
  <div class="container">
    <div class="container-header">
      <div class="zg-input">
        <span class="label">姓名</span>
        <el-input class="input" v-model="input" clearable placeholder="请输入姓名"></el-input>
      </div>
      <div class="zg-input">
        <span class="label">昵称</span>
        <el-input class="input" v-model="input" clearable placeholder="请输入姓名"></el-input>
      </div>
      <div class="zg-input">
        <span class="label">手机</span>
        <el-input class="input" v-model="input" clearable placeholder="请输入姓名"></el-input>
      </div>
      <div class="zg-input">
        <span class="label">性别</span>
        <el-select class="input" v-model="value">
          <el-option label="不限" :value="1"></el-option>
          <el-option label="男" :value="2"></el-option>
          <el-option label="女" :value="3"></el-option>
        </el-select>
      </div>
      <div class="zg-button">
        <el-button type="primary" icon="el-icon-search">搜索</el-button>
      </div>
      <div class="zg-button">
        
      </div>
    </div>

    <el-table
      border
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%">
      
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column prop="name" label="姓名" width="80"></el-table-column>
      <el-table-column prop="name" label="昵称" width="160"></el-table-column>
      <el-table-column prop="name" label="级别" width="140">
        <template slot-scope="scope">
          <span v-if="scope.row.power=='员工'">
            <el-tag size="small">员工</el-tag>
            <el-link @click="setUserPower" type="warning">设为主管</el-link>
          </span>
          <span v-if="scope.row.power=='主管'">
            <el-tag type="danger" size="small">主管</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="加入时间" width="170">
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column>
      <el-table-column label="备注"></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-link @click="setUserPower" type="primary">编辑</el-link>
          <el-link @click="setUserPower" type="primary">移出团队</el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data(){
    return {
      value: 1,
      value1: 1,
      input: '',
      tableData: [{
          date: '2016-05-03 23:59:59',
          name: '王小虎',
          power: '主管'
        }, {
          date: '2016-05-02',
          name: '王小虎',
          power: '员工'
        }, {
          date: '2016-05-04',
          name: '王小虎上',
          power: '员工'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          power: '员工'
        }, {
          date: '2016-05-08',
          name: '王小虎',
          power: '员工'
        }, {
          date: '2016-05-06',
          name: '王小虎',
          power: '员工'
        }],
        multipleSelection: []
    }
  },
  mounted(){
    
  },
  methods: {
    // 设置用户为当前团队的主管
    async setUserPower(){
      this.$confirm('确认将员工王小虎设置为当前团队主管?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '设置成功!'
        });
      }).catch(() => {});
    },

    // 新建团队
    async updateGroup(){
      this.$prompt('请输入团队名称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.$message({
          type: 'success',
          message: '你的邮箱是: ' + value
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });       
      });
    }, 

    // 新增团队人员
    async addUser(){
      this.$alert(`<img style="width: 100%" src="https://image.qll-times.com/2020/05/4965264e8092484bb7fd289d99874d5f.jpg">`, 
        '微信扫描二维码', {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.zg-text-link{
  padding: 10px;
  color: #1a9afd;
  cursor: pointer;
  span{
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    padding: 0 10px;
    &:hover{
      opacity: 0.7;
      text-decoration: underline;
    }
  }
}
@media screen and (max-width: 1395px) {
  .zg-text-link{
    float: left;
  }
}
@media screen and (min-width: 1395px) {
  .zg-text-link{
    float: right;
    line-height: 56px;
  }
}
</style>